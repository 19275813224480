<template>
    <div>
        <h1 class="page__title">Пользователь {{ userName }}</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6">
                    <label for="form-name" class="form-label">Имя</label>
                    <input
                        id="form-name"
                        v-model="form.name"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                        required
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-email" class="form-label">Email</label>
                    <input
                        id="form-email"
                        v-model="form.email"
                        type="email"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                        required
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-phone" class="form-label">Телефон</label>
                    <input
                        id="form-phone"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                        v-model="form.phone"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label class="form-label flex items-center" @click="passwordDisabled = false">
                        <span class="mr-3">Пароль</span>
                        <span v-if="passwordDisabled" class="btn btn-primary"> Сменить пароль </span>
                    </label>

                    <input
                        type="password"
                        class="form-control w-full"
                        :class="{ disabled: passwordDisabled }"
                        placeholder=""
                        :disabled="passwordDisabled"
                        v-model="form.password"
                    />
                </div>

                <div v-if="roles && roles.length > 0" class="col-span-6">
                    <label for="form-roles" class="form-label">Роль <sup>*</sup></label>
                    <Multiselect
                        id="form-roles"
                        name="type"
                        :value="null"
                        :searchable="false"
                        track-by="title"
                        label="title"
                        valueProp="id"
                        :options="roles"
                        :can-clear="false"
                        placeholder="Не выбрано"
                        noResultsText="Не найдено"
                        noOptionsText="Не найдено"
                        v-model="form._role"
                    />
                </div>

                <div v-if="affiliates && affiliates.length > 0 && form._role !== 'super-admin'" class="col-span-6">
                    <label for="form-affiliates" class="form-label">Филиал <sup>*</sup></label>
                    <Multiselect
                        id="form-affiliates"
                        name="type"
                        mode="tags"
                        :value="null"
                        :searchable="false"
                        track-by="name"
                        label="name"
                        valueProp="id"
                        :options="affiliates"
                        :can-clear="false"
                        placeholder="Не выбрано"
                        noResultsText="Не найдено"
                        noOptionsText="Не найдено"
                        v-model="form.assigned_affiliates"
                    />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'users' }" class="btn btn-secondary w-24 mb-2 mr-2"> Отмена </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';

export default {
    name: 'Edit',
    components: { Preloader },
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            userName: false,
            passwordDisabled: true,
            form: {
                name: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                assigned_affiliates: [],
                _role: '',
            },
            affiliates: [],
            roles: [],
        };
    },
    async created() {
        await this.fetchUser();
        await this.fetchAffiliates();
        await this.fetchRoles();
        this.userName = this.form.name;
    },
    methods: {
        async save(flag) {
            try {
                let obj = {
                    email: this.form.email,
                    name: this.form.name,
                    _role: this.form._role,
                    affiliates: this.form.assigned_affiliates,
                };
                if (this.form.password) {
                    obj.password = this.form.password;
                }

                const data = await this.axios.post(
                    '/users/' + this.$route.params.id,
                    obj,
                    {
                        params: {
                            _method: 'put',
                        },
                    }
                );
                if (flag) {
                    this.$notify('Запись сохранена');
                } else {
                    await this.$router.push({ name: 'users' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        async fetchUser() {
            try {
                this.loading = true;
                const { data } = await this.axios.get(`/users/${this.$route.params.id}`);
                this.form = data.data;
                this.form['_role'] =
                    data.data.current_roles && data.data.current_roles ? data.data.current_roles[0] : '';
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error('cant fetch roles: ' + ex);
            }
        },
        async fetchRoles() {
            try {
                // this.loading = true;
                // const { data } = await this.axios.get('/roles', { params: { paginate: false } });
                // this.roles = data.data;
                this.roles = [
                    {
                        id: 'affiliate-admin',
                        title: 'Администратор филиала',
                    },
                    {
                        id: 'super-admin',
                        title: 'Супер администратор',
                    },
                ];
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error('cant fetch roles: ' + ex);
            }
        },
        async fetchAffiliates() {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/affiliates', { params: { paginate: false } });
                this.affiliates = data.data;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error('cant fetch affiliates: ' + ex);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.change__password {
    position: absolute;
    top: 42%;
    right: 0;
}
</style>
